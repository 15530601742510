import React, { useState, useRef, useCallback, useMemo, useContext } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Progress } from 'antd';
import { handleAnalyzeProductRequest } from '../../firebase/analyzeProduct';
import AppContext from '../../context/AppContext';
import { Plus } from 'lucide-react';

const UploadComponent = React.memo(() => {
    const [frontFile, setFrontFile] = useState(null);
    const [ingredientsFile, setIngredientsFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const frontFileInputRef = useRef(null);
    const ingredientsFileInputRef = useRef(null);
    const [frontImage, setFrontImage] = useState(null);
    const [ingredientsImage, setIngredientsImage] = useState(null);

    const {
        setUploadLoadingProgress,
        uploadLoadingProgress,
        setUploadLoadingText,
        uploadLoadingText,
        setProductName,
        setGeneralProductDescription,
        setNegativeIngredients,
        setAlternatives,
    } = useContext(AppContext)

    const isValidImageFile = useCallback((file) => {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validTypes.includes(file.type);
    }, []);

    const handleDrop = useCallback((e, isIngredients = false) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && isValidImageFile(droppedFile)) {
            handleFile(droppedFile, isIngredients);
        } else {
            message.error('Please drop a valid image file (JPEG, PNG, or GIF).');
        }
    }, []);

    const handleFileInput = useCallback((e, isIngredients = false) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && isValidImageFile(selectedFile)) {
            handleFile(selectedFile, isIngredients);
        } else {
            message.error('Please select a valid image file (JPEG, PNG, or GIF).');
        }
    }, [isValidImageFile]);

    const resizeImageIfNeeded = useCallback((file, maxSize = 750) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    let width = img.width;
                    let height = img.height;

                    // Check if resizing is needed
                    if (width <= maxSize && height <= maxSize) {
                        // No resizing needed, resolve with the original file
                        resolve(file);
                        return;
                    }

                    const canvas = document.createElement('canvas');
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        resolve(new File([blob], file.name, { type: file.type }));
                    }, file.type);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const handleFile = useCallback(async (selectedFile, isIngredients = false) => {
        const processedFile = await resizeImageIfNeeded(selectedFile);

        if (isIngredients) {
            setIngredientsFile(processedFile);
        } else {
            setFrontFile(processedFile);
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const base64Image = e.target.result.split(',')[1];
            if (isIngredients) {
                setIngredientsImage(base64Image);
            } else {
                setFrontImage(base64Image);
            }
        };
        reader.readAsDataURL(processedFile);
    }, [resizeImageIfNeeded]);

    const handleAnalyze = useCallback(async () => {
        if (!frontImage) {
            message.error('Please upload a front image of the product.');
            return;
        }

        setIsAnalyzing(true);
        setProgress(0);
        setUploadLoadingProgress(0)
        setUploadLoadingText("Uploading image")

        try {
            setProgress(10);

            const analyzeData = await handleAnalyzeProductRequest({
                frontImageBase64: frontImage,
                ingredientsImageBase64: ingredientsImage
            }, setUploadLoadingProgress, setUploadLoadingText);
            console.log(analyzeData);
            setProgress(100);

            setProductName(analyzeData.productInfo.productName);
            setGeneralProductDescription(analyzeData.productInfo.productExplanation);
            setNegativeIngredients(analyzeData.badIngredientAnalysis);
            setAlternatives(analyzeData.healthyAlternatives);

            message.success('Analysis completed successfully!');
        } catch (error) {
            console.error('Error processing image:', error);

            if (error.details) {
                if (error.details === "Insufficient credits") {
                    message.error({
                        content: 'You have insufficient credits to analyze products. Please add more credits via the account page.',
                        duration: 5, // Duration in seconds
                    });
                } else {
                    message.error('An error occurred while analyzing the product.');
                }
            } else {
                message.error('An error occurred while analyzing the product.');
            }
        } finally {
            setIsAnalyzing(false);
        }
    }, [frontImage, ingredientsImage, setProductName, setNegativeIngredients, setAlternatives]);

    const handleRemoveImage = useCallback((isIngredients = false) => {
        if (isIngredients) {
            setIngredientsFile(null);
            setIngredientsImage(null);
        } else {
            setFrontFile(null);
            setFrontImage(null);
        }
    }, []);

    const uploadAreaStyle = useMemo(() => ({
        border: '2px dashed #A5D6A7',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border-color 0.3s',
        backgroundColor: "white",
        height: "250px",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }), []);

    const uploadAreaStyle2 = useMemo(() => ({
        border: '2px dashed #A5D6A7',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border-color 0.3s',
        backgroundColor: "white",
        height: "50px",
        width: "50px",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }), []);

    const renderUploadArea = (isIngredients = false) => {
        const file = isIngredients ? ingredientsFile : frontFile;
        const inputRef = isIngredients ? ingredientsFileInputRef : frontFileInputRef;
        const style = isIngredients ? uploadAreaStyle2 : uploadAreaStyle;

        if (file) {
            return (
                <div style={{
                    position: 'relative',
                    textAlign: 'center',
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px"
                }}>
                    <div style={{ position: 'relative', maxWidth: '100%', width: 'fit-content' }}>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => handleRemoveImage(isIngredients)}
                            style={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                                zIndex: 1,
                            }}
                        />
                        <img
                            src={URL.createObjectURL(file)}
                            alt={isIngredients ? "Ingredients" : "Uploaded product"}
                            style={{ maxWidth: '100%', maxHeight: '350px', borderRadius: "8px" }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div
                style={style}
                onDrop={(e) => handleDrop(e, isIngredients)}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => inputRef.current.click()}
            >
                <input
                    type="file"
                    ref={inputRef}
                    onChange={(e) => handleFileInput(e, isIngredients)}
                    accept="image/*"
                    style={{ display: 'none' }}
                />
                {isIngredients ? (
                    <div style={{ fontSize: "32px" }}>
                        <Plus />
                    </div>
                ) : (
                    <>
                        <UploadOutlined style={{ fontSize: '64px', color: 'gray' }} />
                        <p style={{ fontSize: "18px", fontWeight: "300" }}>Drop your product image here or click to upload</p>
                    </>
                )}
            </div>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center" }}>
            {renderUploadArea(false)}

            <br />
            <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                {renderUploadArea(true)}
                {!ingredientsFile && (
                    <p style={{ paddingLeft: "16px", fontSize: "18px", fontWeight: "300" }}>Add image of ingredients</p>
                )}
            </div>

            <Button
                style={{
                    marginTop: "20px",
                    fontSize: "32px",
                    fontWeight: "500",
                    padding: "28px",
                    backgroundColor: "#FF5722",
                    color: "white"
                }}
                onClick={handleAnalyze}
                disabled={!frontImage || isAnalyzing}
            >
                {isAnalyzing ? 'Analyzing...' : 'Analyze'}
            </Button>

            {isAnalyzing && (
                <div style={{ textAlign: 'center', marginTop: "20px" }}>
                    <Progress percent={uploadLoadingProgress} status="active" />
                    <p>{uploadLoadingText}</p>
                </div>
            )}
        </div>
    );
});

export default UploadComponent;