// src/context/VideoContext.js
import React, { createContext, useState, useRef, useContext } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';

const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
    const [uploadState, setUploadState] = useState('upload');
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const fileInputRef = useRef(null);
    const ffmpegRef = useRef(null);
    const [extractedAudioUrl, setExtractedAudioUrl] = useState(null);

    const value = {
        uploadState,
        setUploadState,
        file,
        setFile,
        progress,
        setProgress,
        fileInputRef,
        ffmpegRef,
        extractedAudioUrl,
        setExtractedAudioUrl
    };

    return (
        <VideoContext.Provider value={value}>
            {children}
        </VideoContext.Provider>
    );
};

export const useVideo = () => useContext(VideoContext);