import React from 'react';
import { Layout, Button, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import SignupComponent from './Signup/SignupComponent';
import { useAuth } from '../context/AuthContext';

const { Header } = Layout;

const AppHeader = () => {
  const { user } = useAuth();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  }

  const handleAccount = () => {
    navigate('/account');
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const userButton = () => {
    if (user) {
      return (
        <Button  style={{ fontSize: "16px" }} onClick={handleAccount}>Account</Button>
      )
    } else {
      return (
        <Button style={{ fontSize: "16px" }} onClick={handleSignUp}>Sign Up</Button>
      )
    }
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      width: "100%",
      justifyContent: "center",
      display: "flex"
    }}>
      <Col xs={23} sm={23} md={20} xl={16} xxl={12}>
        <div style={{
          backdropFilter: "blur(5px)",
          WebkitBackdropFilter: "blur(5px)",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          border: "solid #A5D6A7 2px",
          borderRadius: "16px",
          marginTop: "16px",
          padding: "8px",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 4px 50px 0 rgba(0, 0, 0, 0.25)"
        }}>
          <div onClick={handleHome}
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              transition: 'transform 0.1s ease',
            }}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            <h1 style={{
              marginTop: "0px",
              marginBottom: "0px",
              // fontWeight: 'bold',
              fontWeight: "700",
              fontSize: '24px',
            }}>
              <span style={{
                color: '#3B3B3B'
              }}>Real</span>
              <span style={{
                color: "white",
                // backgroundColor: "#00712D",
                // backgroundColor: "rgb(198, 246, 213)",
                backgroundColor: '#4CAF50', 
                border: '1px solid #4CAF50',
                borderRadius: "8px",
                marginLeft: "4px",
                padding: "4px"
              }}>Health</span>
            </h1>
          </div>
          {userButton()}
          <SignupComponent
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </div>
      </Col>
    </div>
  );
};

export default AppHeader;