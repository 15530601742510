import React, { useReducer } from 'react';
import AppContext from './AppContext';
import AppReducer from './AppReducer';

const AppState = (props) => {
    const initialState = {
        // Title-related state
        currentTitle: 'Input a video at the top place to generate a youtube title',
        generatedTitles: [],
        titleLoading: false,

        // Description-related state
        currentDescription: 'Input a video at the top place to generate a youtube description',
        generatedDescriptions: [],
        descriptionLoading: false,

        // Tags-related state
        currentTags: ["Input a video at the top place to generate youtube tags"],
        generatedTags: [],
        tagsLoading: false,

        // Transcription-related state
        transcriptionText: 'Input a video at the top place to get your video transcription and subtitle file',
        transcriptionSrt: '',
        transcriptionLoading: false,

        // User-related state
        user: null,
        transcriptionCredits: 0,
        generationCredits: 0,
        subscription: null,

        // Loading
        uploadLoadingProgress: 0,
        uploadLoadingText: "Analyzing...",

        // negatives
        productName: 'Dave Intense Repair Shampoo',
        productNameLoading: false,

        generalProductDescription: "Here is a general explanation of the bad product you uploaded. This is what the product does, and this is why it could be bad for you. Other products from the same brand have been banned in the EU or have been taken off of the market after a lawsuit.",
        generalProductDescriptionLoading: false,

        negativeIngredients: [
            {
                name: "Sodium Lauryl Sulfate",
                shortExplanation: "Can irritate skin and strip natural oils",
                longExplanation: "Sodium Lauryl Sulfate (SLS) is a harsh detergent that can remove natural oils from your hair and skin, potentially causing dryness, irritation, and long-term damage to hair follicles."
            },
            {
                name: "Cocamidopropyl Betaine",
                shortExplanation: "Potential allergen and skin irritant",
                longExplanation: "Although derived from coconut oil, Cocamidopropyl Betaine can cause allergic reactions in some individuals, leading to skin irritation, itching, or even contact dermatitis."
            },
            {
                name: "Parfum (Fragrance)",
                shortExplanation: "May cause allergic reactions",
                longExplanation: "Artificial fragrances can contain numerous chemicals, many of which are potential allergens or irritants. They may cause headaches, skin irritation, or respiratory issues in sensitive individuals."
            },
            {
                name: "Dimethiconol",
                shortExplanation: "Can cause buildup on hair",
                longExplanation: "Dimethiconol is a silicone that can coat the hair, leading to buildup over time. This may make hair appear dull and lifeless, and can be difficult to remove without harsh cleansers."
            },
            {
                name: "Methylisothiazolinone",
                shortExplanation: "Strong allergen, banned in leave-on products in EU",
                longExplanation: "Methylisothiazolinone is a preservative that has been linked to an increase in contact allergies. It's so potent that the EU has banned its use in leave-on cosmetic products."
            }
        ],
        negativeIngredientsLoading: false,


        //positives
        alternatives: [
            {
                alternativeName: "Nurture Naturals Shampoo",
                image: "/api/placeholder/150/150",
                alternativeIngredients: [
                    {
                        name: "Aloe Vera",
                        shortExplanation: "Soothes and moisturizes scalp",
                        longExplanation: "Aloe vera has anti-inflammatory properties that can help soothe an irritated scalp and reduce dandruff. It also provides gentle moisture without leaving hair greasy."
                    },
                    {
                        name: "Jojoba Oil",
                        shortExplanation: "Balances scalp oil production",
                        longExplanation: "Jojoba oil is similar to human sebum, helping to balance oil production in the scalp. It moisturizes hair and scalp without clogging pores or leaving residue."
                    },
                    {
                        name: "Chamomile Extract",
                        shortExplanation: "Adds shine and soothes scalp",
                        longExplanation: "Chamomile has anti-inflammatory properties that can soothe an irritated scalp. It also helps to naturally lighten and brighten hair, adding shine."
                    }
                ],
                alternativeExplanation: "Nurture Naturals Shampoo uses plant-based ingredients to gently cleanse your hair without harsh chemicals. It's suitable for all hair types and helps maintain your scalp's natural balance."
            },
            {
                alternativeName: "EcoClean Clarifying Shampoo",
                image: "/api/placeholder/150/150",
                alternativeIngredients: [
                    {
                        name: "Apple Cider Vinegar",
                        shortExplanation: "Removes buildup and balances pH",
                        longExplanation: "Apple Cider Vinegar helps to remove product buildup from hair while balancing the pH of your scalp. This can lead to shinier, healthier-looking hair."
                    },
                    {
                        name: "Tea Tree Oil",
                        shortExplanation: "Antifungal and clarifying properties",
                        longExplanation: "Tea Tree Oil has natural antifungal properties that can help combat dandruff and other scalp issues. It also helps to clarify the scalp, removing excess oil and buildup."
                    },
                    {
                        name: "Peppermint Oil",
                        shortExplanation: "Stimulates scalp and adds shine",
                        longExplanation: "Peppermint Oil can stimulate blood flow to the scalp, potentially promoting hair growth. It also provides a refreshing, tingling sensation and can add shine to hair."
                    }
                ],
                alternativeExplanation: "EcoClean Clarifying Shampoo is designed to deeply cleanse your hair and scalp, removing buildup without stripping natural oils. It's perfect for occasional use to reset your hair care routine."
            },
            {
                alternativeName: "Herbal Essence Revitalizer",
                image: "/api/placeholder/150/150",
                alternativeIngredients: [
                    {
                        name: "Argan Oil",
                        shortExplanation: "Nourishes and protects hair",
                        longExplanation: "Argan oil is rich in vitamins and antioxidants. It helps to nourish and protect hair from damage, leaving it soft, silky, and shiny."
                    },
                    {
                        name: "Biotin",
                        shortExplanation: "Strengthens hair",
                        longExplanation: "Biotin, also known as Vitamin B7, is essential for healthy hair growth. It helps to strengthen the hair structure, potentially reducing breakage and improving overall hair health."
                    },
                    {
                        name: "Rosemary Extract",
                        shortExplanation: "Promotes hair growth",
                        longExplanation: "Rosemary extract has been shown to improve cellular generation and may stimulate hair growth. It can also improve scalp health and add shine to hair."
                    }
                ],
                alternativeExplanation: "Herbal Essence Revitalizer combines the power of natural oils and vitamins to nourish your hair from root to tip. It's especially beneficial for those with dry or damaged hair seeking to restore their hair's health and vitality."
            }
        ],
        alternativesLoading: false,


        // Error state
        error: null,
    };

    const [state, dispatch] = useReducer(AppReducer, initialState);

    // Action creators
    // Title actions
    const setTitle = (title) => dispatch({ type: 'SET_TITLE', payload: title });
    const setGeneratedTitles = (titles) => dispatch({ type: 'SET_GENERATED_TITLES', payload: titles });
    const setTitleLoading = (loading) => dispatch({ type: 'SET_TITLE_LOADING', payload: loading });

    // Description actions
    const setDescription = (description) => dispatch({ type: 'SET_DESCRIPTION', payload: description });
    const setGeneratedDescriptions = (descriptions) => dispatch({ type: 'SET_GENERATED_DESCRIPTIONS', payload: descriptions });
    const setDescriptionLoading = (loading) => dispatch({ type: 'SET_DESCRIPTION_LOADING', payload: loading });

    // Tags actions
    const setTags = (tags) => dispatch({ type: 'SET_TAGS', payload: tags });
    const setGeneratedTags = (tags) => dispatch({ type: 'SET_GENERATED_TAGS', payload: tags });
    const setTagsLoading = (loading) => dispatch({ type: 'SET_TAGS_LOADING', payload: loading });

    // Transcription actions
    const setTranscription = (text) => dispatch({ type: 'SET_TRANSCRIPTION', payload: text });
    const setTranscriptionSRT = (srt) => dispatch({ type: 'SET_TRANSCRIPTION_SRT', payload: srt });
    const setTranscriptionLoading = (loading) => dispatch({ type: 'SET_TRANSCRIPTION_LOADING', payload: loading });

    // User actions
    const setUser = (user) => dispatch({ type: 'SET_USER', payload: user });
    const setTranscriptionCredits = (transcriptionCredits) => dispatch({ type: 'SET_TRANSCRIPTION_CREDITS', payload: transcriptionCredits });
    const setGenerationCredits = (generationCredits) => dispatch({ type: 'SET_GENERATION_CREDITS', payload: generationCredits });
    const setSubscription = (subscription) => dispatch({ type: 'SET_SUBSCRIPTION', payload: subscription });

    // 
    const setUploadLoadingProgress = (loadingProgress) => dispatch({ type: 'SET_UPLOAD_LOADING_PROGRESS', payload: loadingProgress });
    const setUploadLoadingText = (uploadLoadingText) => dispatch({ type: 'SET_UPLOAD_LOADING_TEXT', payload: uploadLoadingText });

    // Negatives
    const setProductName = (productName) => dispatch({ type: 'SET_PRODUCT_NAME', payload: productName });
    const setProductNameLoading = (loading) => dispatch({ type: 'SET_PRODUCT_NAME_LOADING', payload: loading });

    const setGeneralProductDescription = (generalProductDescription) => dispatch({ type: 'SET_GENERAL_PRODUCT_DESCRIPTION', payload: generalProductDescription });
    const setGeneralProductDescriptionLoading = (loading) => dispatch({ type: 'SET_GENERAL_PRODUCT_DESCRIPTION_LOADING', payload: loading });

    const setNegativeIngredients = (negativeIngredients) => dispatch({ type: 'SET_NEGATIVE_INGREDIENTS', payload: negativeIngredients });
    const setNegativeIngredientsLoading = (loading) => dispatch({ type: 'SET_NEGATIVE_INGREDIENTS_LOADING', payload: loading });

    // Positives
    const setAlternatives = (alternatives) => dispatch({ type: 'SET_ALTERNATIVES', payload: alternatives });
    const setAlternativesLoading = (loading) => dispatch({ type: 'SET_ALTERNATIVES_LOADING', payload: loading });


    // Error action
    const setError = (error) => dispatch({ type: 'SET_ERROR', payload: error });

    return (
        <AppContext.Provider
            value={{
                ...state,
                setTitle,
                setGeneratedTitles,
                setTitleLoading,
                setDescription,
                setGeneratedDescriptions,
                setDescriptionLoading,
                setTags,
                setGeneratedTags,
                setTagsLoading,
                setTranscription,
                setTranscriptionSRT,
                setTranscriptionLoading,
                setUser,
                setTranscriptionCredits,
                setGenerationCredits,
                setSubscription,

                setUploadLoadingProgress,
                setUploadLoadingText,

                setProductName,
                setProductNameLoading,
                setGeneralProductDescription,
                setGeneralProductDescriptionLoading,
                setNegativeIngredients,
                setNegativeIngredientsLoading,
                setAlternatives,
                setAlternativesLoading,

                setError,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppState;