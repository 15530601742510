import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';

// export async function createUserDocument(user) {
//   const userRef = doc(db, 'users', user.uid);
//   const userSnap = await getDoc(userRef);

//   if (!userSnap.exists()) {
//     // New user, create their document
//     const userData = {
//       email: user.email,
//       createdAt: serverTimestamp(),
//       credits: 100,
//       lastCreditRefresh: serverTimestamp(),
//     };
//     await setDoc(userRef, userData);
//   } else {
//     // Existing user, check if we need to refresh credits
//     const userData = userSnap.data();
//     const lastRefresh = userData.lastCreditRefresh.toDate();
//     const now = new Date();
//     const monthsSinceRefresh = (now.getMonth() - lastRefresh.getMonth()) +
//       (12 * (now.getFullYear() - lastRefresh.getFullYear()));

//     if (monthsSinceRefresh > 0) {
//       const newCredits = Math.min(userData.credits + 100, 200); // Cap at 30 credits
//       await setDoc(userRef, {
//         credits: newCredits,
//         lastCreditRefresh: serverTimestamp()
//       }, { merge: true });
//     }
//   }
// }

export async function getUserCredits(user) {
  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();

  return userData
}

export async function getUserGenerationCredits(user) {
  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);
  const userData = userSnap.data();

  return userData.generationCredits
}