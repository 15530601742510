import { useContext, useState } from "react";
import { AlertTriangle, ChevronUp, ChevronDown } from "lucide-react";
import AppContext from "../../context/AppContext";

const NegativesComponent = () => {
    const [expandedIngredients, setExpandedIngredients] = useState({});

    const {
        productName,
        productNameLoading,
        generalProductDescription,
        generalProductDescriptionLoading,
        negativeIngredients,
        negativeIngredientsLoading
    } = useContext(AppContext)

    const toggleIngredientExpansion = (index) => {
        setExpandedIngredients(prev => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <div style={{ marginBottom: '30px', backgroundColor: "white", border: '1px solid #A5D6A7', borderRadius: '8px' }}>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px', paddingTop: "20px" }}>{productName} Analysis</h1>
            <div style={{ padding: '20px' }}>
                <h2 style={{ justifyContent: "center", fontSize: '20px', fontWeight: 'bold', marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                    <AlertTriangle style={{ marginRight: '10px', color: '#e53e3e' }} />
                    Potential Risks
                </h2>
                <p style={{ marginTop: '15px', fontSize: '16px', whiteSpace: "pre-line" }}>{generalProductDescription}</p>
                <h4 style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}>Flagged Ingredients:</h4>

                {negativeIngredients.map((ingredient, index) => (
                    <div key={index} style={{ marginBottom: '16px', backgroundColor: '#fef2f2', border: '1px solid #fee2e2', borderRadius: '8px', padding: '16px' }}>
                        <div style={{ display: "flex", flexDirection: "horizontal", alignItems: "center", justifyContent: "space-between" }}>
                            <h3 style={{ fontWeight: 'bold', margin: "0px" }}>{ingredient.name}</h3>
                            <button
                                onClick={() => toggleIngredientExpansion(index)}
                                style={{
                                    color: '#3182ce',
                                    fontSize: '16px',
                                    fontWeight: "300",
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '5px'
                                }}
                            >
                                {/* {expandedIngredients[index] ? 'Less info' : 'More info'} */}
                                <span style={{ marginLeft: '5px' }}>{expandedIngredients[index] ?
                                    <ChevronUp style={{ marginLeft: '5px' }} /> :
                                    <ChevronDown style={{ marginLeft: '5px' }} />
                                }</span>
                            </button>
                        </div>
                        <p style={{ fontSize: '16px', fontWeight: "500", color: '#e53e3e', marginTop: "8px", marginBottom: "0px" }}>{ingredient.shortExplanation}</p>
                        {/* <button
                            onClick={() => toggleIngredientExpansion(index)}
                            style={{
                                color: '#3182ce',
                                fontSize: '14px',
                                fontWeight: "300",
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '5px'
                            }}
                        >
                            {expandedIngredients[index] ? 'Less info' : 'More info'}
                            <span style={{ marginLeft: '5px' }}>{expandedIngredients[index] ? '▲' : '▼'}</span>
                        </button> */}
                        {expandedIngredients[index] && (
                            <p style={{ marginTop: '10px', marginBottom: "0px", fontSize: '16px', fontWeight: "400", color: '#4a5568', whiteSpace: "pre-line" }}>{ingredient.longExplanation}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default NegativesComponent