import React from 'react';
import { Button, Modal } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { signInWithGoogle } from '../../firebase/Authentication';
import { useAuth } from '../../context/AuthContext';

const SignupComponent = ({ isModalVisible, setIsModalVisible }) => {
    const { user } = useAuth();

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            setIsModalVisible(false);
        } catch (error) {
            console.error("Error signing in with Google", error);
        }
    };

    const handleCheckout = () => {
        // Implement your checkout/subscribe logic here
    };

    return (
        <Modal
            title={<span style={{ fontSize: "24px" }}>Sign Up</span>}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <Button
                icon={<GoogleOutlined />}
                onClick={handleGoogleSignIn}
                disabled={user !== null}
                style={{ marginTop: 32, marginBottom: 16, width: '100%', fontSize: "24px", padding: "32px" }}
            >
                <span style={{ fontSize: "18px" }}>Sign Up with Google</span>
            </Button>

            <Button
                type="primary"
                onClick={handleCheckout}
                disabled={user === null}
                style={{ width: '100%', fontSize: "24px", padding: "32px" }}
            >
                <span style={{ fontSize: "18px" }}>{user ? 'Checkout' : 'Sign up to Checkout'}</span>
            </Button>
        </Modal>
    );
};

export default SignupComponent;