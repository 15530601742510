import { useContext } from "react";
import AppContext from "../context/AppContext";
import { auth } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

// Create callable functions
const analyzeProductImages = httpsCallable(functions, 'analyzeProductImages');
const identifyIngredients = httpsCallable(functions, 'identifyIngredients');
const tagIngredients = httpsCallable(functions, 'tagIngredients');
const analyzeBadIngredients = httpsCallable(functions, 'analyzeBadIngredients');
const suggestAlternatives = httpsCallable(functions, 'suggestAlternatives');

export async function handleAnalyzeProductRequest(picture, setUploadLoadingProgress, setUploadLoadingText) {

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    try {
        console.log("picture: ", picture);

        // Step 1: Analyze product images
        const productInfoResult = await analyzeProductImages({
            // frontImageBase64: picture,
            frontImageBase64: picture?.frontImageBase64,
            ingredientsImageBase64: picture?.ingredientsImageBase64
        });
        console.log("Product Info:", productInfoResult);
        const productInfo = productInfoResult.data;
        setUploadLoadingProgress(20)
        setUploadLoadingText("Identifying ingredients")

        // Step 2: Identify ingredients
        const ingredientsResult = await identifyIngredients({
            productInfo,
            frontImageBase64: picture?.frontImageBase64,
            ingredientsImageBase64: picture?.ingredientsImageBase64
        });
        console.log("ingredientsResult:", ingredientsResult);
        const ingredients = ingredientsResult.data;
        setUploadLoadingProgress(40)
        setUploadLoadingText("Analyzing ingredients")

        // Step 3: Tag ingredients
        const taggedIngredientsResult = await tagIngredients({
            productInfo,
            ingredients
        });
        const taggedIngredients = taggedIngredientsResult.data;
        console.log("Tagged Ingredients:", taggedIngredients);
        setUploadLoadingProgress(60)
        setUploadLoadingText("Analyzing potential risks")

        // Step 4: Analyze bad ingredients
        const badIngredients = taggedIngredients.filter(ing => ing.tag === 'bad');
        const badIngredientAnalysisResult = await analyzeBadIngredients({
            productInfo,
            badIngredients
        });
        console.log("badIngredientAnalysisResult:", badIngredientAnalysisResult);
        const badIngredientAnalysis = badIngredientAnalysisResult.data;
        setUploadLoadingProgress(80)
        setUploadLoadingText("Finding healthy alternatives")

        // Step 5: Suggest alternatives
        const healthyAlternativesResult = await suggestAlternatives({
            productInfo,
            ingredientAnalysis: badIngredientAnalysis
        });
        const healthyAlternatives = healthyAlternativesResult.data;
        console.log("Healthy Alternatives:", healthyAlternatives);
        setUploadLoadingProgress(100)
        setUploadLoadingText("Finishing up")

        // Combine all results
        const finalResult = {
            productInfo,
            ingredients: taggedIngredients,
            badIngredientAnalysis,
            healthyAlternatives
        };

        console.log("Final Result:", finalResult);
        return finalResult;
    } catch (error) {
        console.error("Error in product analysis:", error);
        throw error;
    }
}