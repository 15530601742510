import React, { useState, useEffect, useRef } from 'react';

const words = ["Right", "Healthy", "Natural", "Safe"];


const HeroComponent = () => {
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const [isWrapped, setIsWrapped] = useState(false);
    const h1Ref = useRef(null);
    const firstSpanRef = useRef(null);
    const secondSpanRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(true);
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % words.length);
                setFade(false);
            }, 200); // Wait for fade out, then change word
        }, 1000); // Change every 3 seconds

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const checkWrapping = () => {
            if (h1Ref.current && firstSpanRef.current && secondSpanRef.current) {
                const h1Rect = h1Ref.current.getBoundingClientRect();
                const firstSpanRect = firstSpanRef.current.getBoundingClientRect();
                const secondSpanRect = secondSpanRef.current.getBoundingClientRect();

                // Check if the second span is below the first span
                setIsWrapped(secondSpanRect.top >= firstSpanRect.bottom - 1); // -1 for any minor discrepancies
            }
        };

        checkWrapping();
        window.addEventListener('resize', checkWrapping);

        // Create a MutationObserver to watch for changes in the h1 content
        const observer = new MutationObserver(checkWrapping);
        observer.observe(h1Ref.current, { childList: true, subtree: true, characterData: true });

        return () => {
            window.removeEventListener('resize', checkWrapping);
            observer.disconnect();
        };
    }, []);

    return (
        <div style={{ width: "100%", justifyContent: "center", textAlign: "center" }}>
            <div style={{}}>
                <h1 style={{
                    marginBottom: "0px", lineHeight: "50px",
                }}
                >
                    <span
                        style={{
                            // fontWeight: 'bold',
                            fontSize: '36px',
                            color: '#3B3B3B'
                        }}>Know What's Inside</span>
                    {/* <span style={{
                        fontWeight: 'bold',
                        fontSize: '36px',
                        color: 'white',
                        backgroundColor: "#FF4E4E",
                        borderRadius: "8px",
                        padding: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px"

                    }}>Live</span> */}
                </h1>
            </div>
            <h1
                ref={h1Ref}
                style={{
                    marginTop: "0px",
                    // fontWeight: 'bold',
                    fontSize: '36px',
                    color: '#3B3B3B',
                    display: 'flex',
                    flexDirection: "horizontal",
                    flexWrap: "wrap",
                    // gap: '8px 16px', // vertical horizontal
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <span
                    ref={firstSpanRef}
                    style={{ paddingLeft: "32px", marginBottom: "16px" }}>Choose What's  </span>
                <span
                    ref={secondSpanRef}
                    style={{
                        paddingLeft: "16px",
                        display: 'inline-block',
                        width: '240px', // Adjust this value based on your longest word
                        textAlign: isWrapped ? 'center' : 'left',
                        transition: 'opacity 0.2s ease-in-out',
                        opacity: fade ? 0 : 1,
                        marginBottom: "16px"
                    }}
                >
                    <span style={{
                        color: 'white',
                        backgroundColor: "#4CAF50",
                        // backgroundColor: "rgb(198, 246, 213)",
                        border: '1px solid #4CAF50',
                        borderRadius: "8px",
                        padding: "4px",
                        paddingLeft: "8px",
                        paddingRight: "8px"
                    }}>

                        {words[index]}
                    </span>
                </span>
            </h1>

            {/* <h2 style={{
                // fontWeight: '400',
                fontSize: '24px',
                color: '#777777'
            }}>Scan products, understand risks, and find safer alternatives effortlessly</h2> */}
            <h2 style={{
                // fontWeight: '400',
                fontSize: '24px',
                color: '#777777'
            }}>
                <span style={{ paddingRight: "8px", color: "#4096ff" }} >Scan</span>
                <span style={{ paddingRight: "8px" }} >products, </span>
                <span style={{ paddingRight: "8px", color: "rgb(229, 62, 62)" }} >understand</span>
                <span style={{ paddingRight: "8px" }} >risks,</span>
                <span style={{ paddingRight: "8px" }} >and</span>
                <span style={{ paddingRight: "8px", color: "rgb(76, 175, 80)" }} >find</span>
                <span style={{ paddingRight: "8px", display: "inline-grid" }} >safer alternatives effortlessly</span>
            </h2>
        </div>
    );
};

export default HeroComponent;