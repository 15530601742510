import React from 'react';
import AppHeader from '../components/Header';
import { Col, Row } from 'antd';
import AccountComponent from '../components/Account/AccountComponent';

const Account = () => {
    return (
        <div style={{ backgroundColor: "#F5F5F5", minHeight: "100vh" }}>

            <AppHeader />
            <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                <Col xs={23} sm={23} md={20} xl={16} xxl={10}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <h2> ACCCOUUJUNTT</h2> */}
                    <AccountComponent />

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* Add your components here */}
                </Col>
            </div>
        </div>
    );
};

export default Account;