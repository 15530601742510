import React from 'react';

const AmazonSearchLink = ({ searchTerm }) => {
  const encodedSearchTerm = encodeURIComponent(searchTerm);
  const amazonSearchUrl = `https://www.amazon.com/s?k=${encodedSearchTerm}`;

  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#FF9900',
    textDecoration: 'none',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    cursor: 'pointer',
  };

  const iconStyle = {
    width: '20px',
    height: '20px',
    marginRight: '8px',
  };

  return (
    <a
      href={amazonSearchUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={buttonStyle}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#FFB84D'}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FF9900'}
    >
      <svg
        style={iconStyle}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
      </svg>
      Find on Amazon
      {/* Find {searchTerm} on Amazon */}
    </a>
  );
};

export default AmazonSearchLink;