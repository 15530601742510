import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const initiateSubscription = (userId) => {
  // Make sure the Lemon.js script has loaded
  if (window.createLemonSqueezy) {

    const checkoutUrl = 'https://yougen.lemonsqueezy.com/buy/315a675b-f707-4594-bf24-b29478bb4798?embed=1&discount=0';
    window.LemonSqueezy.Url.Open(checkoutUrl);

  } else {
    console.error('Lemon.js script not loaded');
  }
};

const SubscriptionButton = ({ variantId, style }) => {
  const { user } = useAuth();
  const functions = getFunctions();

  useEffect(() => {
    // Manually initialize Lemon.js after the component has mounted
    window.createLemonSqueezy();
  }, []);

  const handleSubscribe = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const createCheckout = httpsCallable(functions, 'createLemonSqueezyCheckout');
      const result = await createCheckout(variantId);

      // Extract the checkout URL from the response
      const checkoutUrl = result.data.data.attributes.url;

      if (checkoutUrl) {
        // Open the checkout URL
        window.LemonSqueezy.Url.Open(checkoutUrl)
        // window.open(checkoutUrl, '_blank');
      } else {
        console.error('Checkout URL not found in the response');
      }
    } catch (error) {
      console.error('Error creating checkout:', error);
    }
  };

  return (
    <div>
      {/* <a
        href="https://yougen.lemonsqueezy.com/buy/315a675b-f707-4594-bf24-b29478bb4798?embed=1&discount=0"
        class="lemonsqueezy-button"
      >
        Buy YouGen Subscription
      </a> */}
      <Button
        type='primary'
        // style={{ fontSize: "16px", fontWeight: "500", color: "#3C3C3C", padding: "16px" }}
        onClick={() => handleSubscribe()}
        style={style}
      >
        Subscribe Now
      </Button>
    </div>
  );
};

export default SubscriptionButton;