import { ChevronDown, ChevronUp, Leaf } from 'lucide-react';
import React, { useContext, useState } from 'react';
import AppContext from '../../context/AppContext';
import AmazonSearchLink from './AmazonSearchLink';

const PositivesComponent = () => {
    const [currentAlternative, setCurrentAlternative] = useState(0);
    const [expandedAlternatives, setExpandedAlternatives] = useState({});
    const [expandedIngredients, setExpandedIngredients] = useState({});

    const {
        alternatives,
        alternativesLoading,
    } = useContext(AppContext)

    const nextAlternative = () => {
        setCurrentAlternative((prev) => (prev + 1) % alternatives.length);
    };

    const prevAlternative = () => {
        setCurrentAlternative((prev) => (prev - 1 + alternatives.length) % alternatives.length);
    };

    const toggleAlternativeExpansion = (id) => {
        setExpandedAlternatives(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };
    const toggleIngredientExpansion = (id) => {
        setExpandedIngredients(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    let alt = alternatives[currentAlternative];
    if (alternatives.length === 0) {
        return (
            <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '20px' }}>
                <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '15px' }}>Healthier Alternatives</h2>
                <p>No alternatives available at the moment.</p>
            </div>
        );
    }

    return (
        <div style={{ backgroundColor: 'white', marginBottom: '30px', border: '1px solid #A5D6A7', borderRadius: '8px', padding: '20px' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>
                <Leaf style={{ marginRight: '10px', color: '#38a169' }} />
                Healthier Alternatives
            </h2>
            <div>
                {/* <img src={alt.image} alt={alt.alternativeName} style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '8px', margin: '0 auto 15px', display: 'block' }} /> */}
                <h3 style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', marginBottom: '15px' }}>{alt.alternativeName}</h3>
                <div>
                    <p style={{ marginTop: '15px', fontSize: '16px', whiteSpace: "pre-line" }}>{alt.alternativeExplanation}</p>
                    <div style={{display: "flex", justifyContent: 'center'}}>

                    <AmazonSearchLink searchTerm={alt.alternativeName} />
                    </div>
                    <h4 style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}>Positive Ingredients:</h4>
                    {alt.alternativeIngredients.map((ingredient, idx) => (
                        <div key={idx} style={{ marginBottom: '15px', backgroundColor: '#f0fff4', border: '1px solid #c6f6d5', borderRadius: '8px', padding: '15px' }}>
                            <div style={{display: "flex", flexDirection: "horizontal", alignItems: "center", justifyContent: "space-between" }}>
                                <h3 style={{ fontWeight: 'bold', margin: "0px" }}>{ingredient.name}</h3>
                                <button
                                    onClick={() => toggleIngredientExpansion(`${currentAlternative}-${idx}`)}
                                    style={{
                                        color: '#3182ce',
                                        // color: '#38a169',
                                        fontSize: '16px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '5px'
                                    }}
                                >
                                    {/* {expandedIngredients[`${currentAlternative}-${idx}`] ? 'Less info' : 'More info'} */}
                                    {expandedIngredients[`${currentAlternative}-${idx}`] ?
                                        <ChevronUp style={{ marginLeft: '5px' }} /> :
                                        <ChevronDown style={{ marginLeft: '5px' }} />
                                    }
                                </button>
                            </div>
                            <p style={{ fontSize: '16px', color: '#38a169', marginTop: "8px", marginBottom: "0px", fontWeight: "400" }}>{ingredient.shortExplanation}</p>

                            {
                                expandedIngredients[`${currentAlternative}-${idx}`] && (
                                    <p style={{ marginTop: '10px', marginBottom: "0px", fontSize: '16px', color: '#4a5568', fontWeight: "400", whiteSpace: "pre-line" }}>{ingredient.longExplanation}</p>
                                )
                            }
                        </div>
                    ))}
                </div>
                
                {alternatives.length > 1 && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <button onClick={prevAlternative} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '20px', margin: '0 10px' }}>
                            ←
                        </button>
                        <span>{currentAlternative + 1} / {alternatives.length}</span>
                        <button onClick={nextAlternative} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '20px', margin: '0 10px' }}>
                            →
                        </button>
                    </div>
                )}
            </div>
        </div >
    );
};

export default PositivesComponent;