const AppReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TITLE':
            return { ...state, currentTitle: action.payload };
        case 'SET_GENERATED_TITLES':
            return { ...state, generatedTitles: action.payload };
        case 'SET_TITLE_LOADING':
            return { ...state, titleLoading: action.payload };

        case 'SET_DESCRIPTION':
            return { ...state, currentDescription: action.payload };
        case 'SET_GENERATED_DESCRIPTIONS':
            return { ...state, generatedDescriptions: action.payload };
        case 'SET_DESCRIPTION_LOADING':
            return { ...state, descriptionLoading: action.payload };

        case 'SET_TAGS':
            return { ...state, currentTags: action.payload };
        case 'SET_GENERATED_TAGS':
            return { ...state, generatedTags: action.payload };
        case 'SET_TAGS_LOADING':
            return { ...state, tagsLoading: action.payload };

        case 'SET_TRANSCRIPTION':
            return { ...state, transcriptionText: action.payload };
        case 'SET_TRANSCRIPTION_SRT':
            return { ...state, transcriptionSrt: action.payload };
        case 'SET_TRANSCRIPTION_LOADING':
            return { ...state, transcriptionLoading: action.payload };

        case 'SET_UPLOAD_LOADING_PROGRESS':
            return { ...state, uploadLoadingProgress: action.payload};
        case 'SET_UPLOAD_LOADING_TEXT':
            return { ...state, uploadLoadingText: action.payload};

        // Negatives
        case 'SET_PRODUCT_NAME':
            return { ...state, productName: action.payload };
        case 'SET_PRODUCT_NAME_LOADING':
            return { ...state, productNameLoading: action.payload };

        case 'SET_GENERAL_PRODUCT_DESCRIPTION':
            return { ...state, generalProductDescription: action.payload };
        case 'SET_GENERAL_PRODUCT_DESCRIPTION_LOADING':
            return { ...state, generalProductDescriptionLoading: action.payload };

        case 'SET_NEGATIVE_INGREDIENTS':
            return { ...state, negativeIngredients: action.payload };
        case 'SET_NEGATIVE_INGREDIENTS_LOADING':
            return { ...state, negativeIngredientsLoading: action.payload };

        // Positives
        case 'SET_ALTERNATIVES':
            return { ...state, alternatives: action.payload }
        case 'SET_ALTERNATIVES_LOADING':
            return { ...state, alternativesLoading: action.payload }

        default:
            return state;
    }
};

export default AppReducer;